import React, {Component} from 'react';
import {css} from '@emotion/core';
import {navigate} from 'gatsby';

let QrReader;

export default class QRReader extends Component {
	constructor() {
		super();
		this.state = {didLibraryLoad: false, result: 'No result'};
	}

	componentDidMount() {
		// To ensure react-qr-reader to be required only in clint side,
		// as it does not have SSR
		QrReader = require('react-qr-reader');
		this.setState({didLibraryLoad: true});
	}

	handleScan = (data) => {
		if (data) {
			this.setState({
				result: data,
			});
			navigate('/merchant');
		}
	};

	handleError = (err) => {
		console.error(err);
	};

	render() {
		return (
			<div
				css={css`
					width: 100%;
					position: absolute;
				`}
			>
				{this.state.didLibraryLoad ? (
					<React.Fragment>
						<QrReader
							delay={0}
							onError={this.handleError}
							onScan={this.handleScan}
							style={{margin: '20vw auto', width: '80vw', outline: 'blue'}}
						/>
						<p
							css={css`
								margin: 5vh;
								width: 50vw;
							`}
						>
							{this.state.result}
						</p>
					</React.Fragment>
				) : (
					`Loading QR reader`
				)}
			</div>
		);
	}
}
